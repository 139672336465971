document.addEventListener("DOMContentLoaded", function () {
  var video = document.getElementById("video");
  if (video) {
    var toggle = document.getElementById("toggle");
    var overlay = document.getElementById("overlay");

    toggle.style.display = "block";
    overlay.style.display = "block";

    overlay.addEventListener("click", function () {
      if (video.muted) {
        video.muted = false;
        toggle.style.display = "none";
      } else {
        video.muted = true;
        toggle.style.display = "block";
      }
    });
  }
});
