export function setupSubmitButtonHandler() {
  var cf7SubmitButtons = document.querySelectorAll('.wpcf7-submit');

  cf7SubmitButtons.forEach(function (button) {
    var buttonWrapper = button.closest('.newsletter__button-wrapper');
    button.addEventListener('click', function () {
      button.dataset.originalText = button.value;
      button.value = '';
      var customSpinner = buttonWrapper.querySelector('.custom-spinner');
      if (customSpinner) {
        customSpinner.style.display = 'flex';
      }
    });
  });

  function restoreSubmitButton(button) {
    var buttonWrapper = button.closest('.newsletter__button-wrapper');
    if (button && button.dataset.originalText) {
      button.value = button.dataset.originalText;
      var customSpinner = buttonWrapper.querySelector('.custom-spinner');
      if (customSpinner) {
        customSpinner.style.display = 'none';
      }
    }
  }

  document.addEventListener('wpcf7mailsent', function (event) {
    var form = event.target;
    var submitButton = form.querySelector('.wpcf7-submit');
    restoreSubmitButton(submitButton);
  }, false);

  document.addEventListener('wpcf7invalid', function (event) {
    var form = event.target;
    var submitButton = form.querySelector('.wpcf7-submit');
    restoreSubmitButton(submitButton);
  }, false);

  document.addEventListener('wpcf7mailfailed', function (event) {
    var form = event.target;
    var submitButton = form.querySelector('.wpcf7-submit');
    restoreSubmitButton(submitButton);
  }, false);
}
