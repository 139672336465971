import './scss/style.scss';

import './js/popup.js';
import './js/cookie.js';
import './js/eventVideo.js';

import { wrapForm } from './js/formWrapper.js';
import { setupHamburgerMenu } from './js/hamburgerMenu.js';
import { setupScrollUp } from './js/scrollUp.js';
import { setupSubmitButtonHandler } from './js/submitButtonHandler.js';

document.addEventListener('DOMContentLoaded', function () {
  setupHamburgerMenu();
  setupSubmitButtonHandler();
});

// window.onload = function () {
//   wrapForm();
// };

setupScrollUp();

/*=============== SCROLL REVEAL ANIMATION ===============*/
const sr = ScrollReveal({
  origin: "top",
  distance: "60px",
  duration: 1200,
  delay: 400,
});

sr.reveal(`.animation`);
sr.reveal(`.animation__left`, { origin: "left" });
sr.reveal(`.animation__right`, { origin: "right" });
sr.reveal(`.animation__interval`, { interval: 50 });

document.addEventListener("DOMContentLoaded", function () {
  var fastForwardIcon = document.getElementById("fastForwardIcon");
  if (fastForwardIcon) {
      fastForwardIcon.style.display = "block";

      fastForwardIcon.addEventListener("click", function () {
          var mySection = document.getElementById("scroll-dow");
          if (mySection) {
              mySection.scrollIntoView({
                  behavior: 'smooth'
              });
          }
      });
  }
});
